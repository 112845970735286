import { View } from 'backbone';
import Chart from 'chart';
import { getOwnerId } from '../../owner/ownerId';
import getSpinner from '../../spinner';
import AccountStatementsCollection from '../collections/statements';
import AccountStatementListView from './statement-list';
import AccountPropertiesListView from './property-list';
import template from '../templates/detail.hbs';
import PropertiesCollection from '../../property/collections/properties';

const spinner = getSpinner();

export default View.extend({

    className: 'container',

    template: template,

    // events: {},

    initialize: function () {
        // Create subviews
        this.subviews = {
            properties: new AccountPropertiesListView({
                collection: new PropertiesCollection(),
            }),
            statements: new AccountStatementListView({
                model: this.model,
                collection: new AccountStatementsCollection(null, {
                    accountId: this.model.id,
                }),
            }),
        };

        // When model changes; render
        this.listenTo(this.model, 'change', this.render);

        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            spinner.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            spinner.stop();
        });

        // When statements subview collection updates; render
        this.listenTo(this.subviews.statements.collection, 'update', this.renderChart);
    },

    render: function () {
        console.debug('Detail#render');

        this.el.innerHTML = this.template({
            account: this.model.toJSON(),
        });

        // Bind properties view
        this.subviews.properties.setElement(this.$el.find('#properties'));

        // Bind statements view
        this.subviews.statements.setElement(this.$el.find('#statements'));

        // Initiate fetch of properties
        this.subviews.properties.collection.fetch({
            data: {
                owner_code: getOwnerId(),
                account_id: this.model.get('id'),
            },
        });

        // Initiate fetch of statements
        this.subviews.statements.collection.fetch({
            data: {
                owner_id: getOwnerId(),
                inclusions: ['attachment'],
                limit: 24,
            },
        });

        return this;
    },

    renderChart: function () {
        console.debug('Detail#renderChart');

        const green = tinycolor('#28a745').brighten();

        const collection = this.subviews.statements.collection.toJSON().reverse();

        const chartData = {
            labels: collection.map(statement => statement.year + '/' + statement.month),
            datasets: [{
                label: 'Balance',
                data: collection.map(statement => statement.balance),
                fill: false,
                lineTension: 0,
                borderColor: green.toRgbString(),
                backgroundColor: green.toRgbString(),
                hoverBackgroundColor: green.brighten().toRgbString(),
            }]
        };

        const ctx = this.el.querySelector('canvas.chart');
        this.chart = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: {
                easing: 'easeOutQuint',
                legend: {
                    position: 'bottom',
                },
                scales: {
                    yAxes: {
                        ticks: {
                            callback: (value) => {
                                return numeral(value).format('0,0');
                            },
                        },
                    },
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem) => {
                            return numeral(tooltipItem.value).format('0,0');
                        },
                    },
                },
            },
        });
    }

});