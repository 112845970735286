import { View } from 'backbone';
import PropertyListView from './list';
import PropertiesCollection from '../collections/properties';
import { getOwnerId } from '../../owner/ownerId';
import template from '../templates/index.hbs';

export default View.extend({

    className: 'container',

    template: template,

    // events: {},

    initialize: function() {
        // Create subviews
        this.subviews = {
            properties: new PropertyListView({
                    collection: new PropertiesCollection(),
            }),
        };
    },

    render: function() {
        console.debug('Index#render');

        this.el.innerHTML = this.template();

        // Bind properties view
        this.subviews.properties.setElement(this.$el.find('#list'));

        // Initiate fetch
        this.subviews.properties.collection.fetch({
            data: {
                owner_code: getOwnerId()
            }
        });

        return this;
    },

});