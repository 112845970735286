export default {
    auth: {
        url: process.env.AUTH_URL || '',
        client_id: process.env.AUTH_CLIENT_ID || '',
        client_secret: process.env.AUTH_CLIENT_SECRET || ''
    },
    portal_api: {
        url: `${process.env.API_URL}/portal` || '',
    },
    api: {
        url: process.env.API_URL || '',
    },
    google: {
        maps: {
            api_key: process.env.GOOGLE_MAPS_API_KEY || '',
        }
    }
};