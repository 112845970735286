var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"room_number") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":60},"end":{"line":18,"column":90}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return ", ";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\n                        <td>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"period") : depth0), depth0))
    + "</td>\n                        <td class=\""
    + alias1(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"credit") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":78,"column":35},"end":{"line":78,"column":62}}}))
    + "\">&yen;"
    + alias1(__default(require("../../../handlebars-helpers/numeral.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"credit") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":78,"column":69},"end":{"line":78,"column":98}}}))
    + "</td>\n                        <td class=\""
    + alias1(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"debit") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":79,"column":35},"end":{"line":79,"column":61}}}))
    + "\">&yen;"
    + alias1(__default(require("../../../handlebars-helpers/numeral.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"debit") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":79,"column":68},"end":{"line":79,"column":96}}}))
    + "</td>\n                        <td class=\""
    + alias1(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"net") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":80,"column":35},"end":{"line":80,"column":59}}}))
    + "\">&yen;"
    + alias1(__default(require("../../../handlebars-helpers/numeral.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"net") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":80,"column":66},"end":{"line":80,"column":92}}}))
    + "</td>\n                    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n\n<hr>\n\n<div class=\"row\">\n    <div class=\"col-md-6 mb-3\">\n        <div class=\"card\">\n            <div id=\"map\" style=\"height: 312px\"></div>\n        </div>\n    </div>\n\n    <div class=\"col-md-6\">\n        <dl class=\"row mb-3\">\n            <dt class=\"col-sm-4 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.location",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":72}}}))
    + "</dt>\n            <dd class=\"col-sm-8 mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/propertyAddress.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"property") : depth0),{"name":"propertyAddress","hash":{},"data":data,"loc":{"start":{"line":15,"column":38},"end":{"line":15,"column":66}}}))
    + "</dd>\n            <dt class=\"col-sm-4 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.rooms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":69}}}))
    + "</dt>\n            <dd class=\"col-sm-8 mb-0\">\n                "
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"rooms") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":99}}})) != null ? stack1 : "")
    + "\n            </dd>\n            <dt class=\"col-sm-4 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":20,"column":71}}}))
    + "</dt>\n            <dd class=\"col-sm-8 mb-0\">\n                <a href=\"#account/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n                    931 "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"deposit_account_number") : stack1), depth0))
    + "\n                </a>\n            </dd>\n        </dl>\n\n        <div class=\"card mb-3\">\n            <div class=\"card-body\">\n                <h2 class=\"card-title h4\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.twelve_month_summary",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":42},"end":{"line":30,"column":79}}}))
    + "</h2>\n\n                <table class=\"table mb-0\">\n                    <tr>\n                        <th scope=\"row\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.credit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":34,"column":63}}}))
    + "</th>\n                        <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"twelve_month_cashflow") : stack1)) != null ? lookupProperty(stack1,"credit") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":35,"column":46},"end":{"line":35,"column":99}}}))
    + "\">\n                            &yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"twelve_month_cashflow") : stack1)) != null ? lookupProperty(stack1,"credit") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":36,"column":33},"end":{"line":36,"column":88}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <th scope=\"row\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.debit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":40},"end":{"line":40,"column":62}}}))
    + "</th>\n                        <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"twelve_month_cashflow") : stack1)) != null ? lookupProperty(stack1,"debit") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":41,"column":46},"end":{"line":41,"column":98}}}))
    + "\">\n                            &yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"twelve_month_cashflow") : stack1)) != null ? lookupProperty(stack1,"debit") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":42,"column":33},"end":{"line":42,"column":87}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <th scope=\"row\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.net",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":40},"end":{"line":46,"column":60}}}))
    + "</th>\n                        <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"twelve_month_cashflow") : stack1)) != null ? lookupProperty(stack1,"net") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":47,"column":46},"end":{"line":47,"column":96}}}))
    + "\">\n                            &yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"twelve_month_cashflow") : stack1)) != null ? lookupProperty(stack1,"net") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":48,"column":33},"end":{"line":48,"column":85}}}))
    + "\n                        </td>\n                    </tr>\n                </table>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"card mb-3\">\n    <div class=\"card-body\">\n        <h2 class=\"card-title h4\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.twelve_month_breakdown",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":34},"end":{"line":59,"column":73}}}))
    + "</h2>\n\n        <canvas class=\"chart my-3 d-none d-md-block\" style=\"width: 100%; height: 400px\"></canvas>\n\n        <div class=\"table-responsive\">\n            <table class=\"table mb-0\">\n                <thead>\n                    <tr>\n                        <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":28},"end":{"line":67,"column":51}}}))
    + "</th>\n                        <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.credit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":28},"end":{"line":68,"column":51}}}))
    + "</th>\n                        <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.debit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":28},"end":{"line":69,"column":50}}}))
    + "</th>\n                        <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"property.net",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":28},"end":{"line":70,"column":48}}}))
    + "</th>\n                    </tr>\n                </thead>\n\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"monthly_cashflow") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":20},"end":{"line":82,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n        </div>\n    </div>\n</div>";
},"useData":true});