import { bindAll } from 'underscore';
import { View, history } from 'backbone';
import oAuth2Client from '../../oauth2-client';
import yuyan from '../../yuyan';
import template from '../templates/login.hbs';
import '../../../../css/login.css';

export default View.extend({

    className: 'container',

    template: template,

    events: {
        'submit form': 'login',
    },

    initialize: function() {
        // Make sure these functions run with correct 'this' context
        bindAll(this, 'login');
    },

    render: function() {
        console.debug('Login#render');

        this.el.innerHTML = this.template();

        // Set focus
        this.el.querySelector('#field-email').focus();

        return this;
    },

    login: function($e) {
        console.debug('Login#login');

        $e.preventDefault();

        const cntrAlert = this.el.querySelector('.alert');

        // Hide existing alerts while attempting to login
        cntrAlert.classList.add('d-none');

        // Get username and password
        const username = this.el.querySelector('#field-email').value;
        const password = this.el.querySelector('#field-password').value;

        // Make AJAX call to login
        oAuth2Client.authenticate(username, password)
        .then(() => {
            console.info('Login#login: Login successful');

            // Navigate to dashboard
            /** @todo Navigate to initial desired route */
            history.navigate('', {trigger: true});
        })
        .catch((error) => {
            console.warn('Login#login: Login failed');

            let errorKey = 'authentication.error.' + error.name;

            if (!yuyan.has(errorKey)) {
                errorKey = 'authentication.error.generic';
            }

            // Update alert text and show
            cntrAlert.innerHTML = yuyan.t(errorKey);
            cntrAlert.classList.remove('d-none');
        });
    },

});