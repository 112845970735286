import { View } from 'backbone';
import { getOwnerId } from '../../owner/ownerId';
import AccountsCollections from '../collections/accounts';
import AccountListView from './list';
import template from '../templates/index.hbs';

export default View.extend({

    className: 'container',

    template: template,

    // events: {},

    initialize: function () {
        // Create subviews
        this.subviews = {
            accounts: new AccountListView({
                collection: new AccountsCollections(),
            }),
        };
    },

    render: function () {
        console.debug('AccountListView#render');

        this.el.innerHTML = this.template();

        // Bind accounts view
        this.subviews.accounts.setElement(this.$el.find('#list'));

        // Initiate fetch
        this.subviews.accounts.collection.fetch({
            data: {
                owner_id: getOwnerId(),
                inclusions: [ 'balance' ]
            }
        });

        return this;
    },

});