import { ajax } from 'jquery';
import { extend } from 'underscore';
import { get, remove, set } from '../libs/local-storage';

/**
 * OAuth2 Client
 * @param {object} options - Options for OAuth2 client
 */
export default class OAuth2Client {
    constructor(options) {
        /** Merge options with defaults */
        this.options = extend({
            url: '',
            client_id: null,
            client_secret: null,
            token_name: 'access-token',
            onRefreshFail: null,
        }, options || {});

        /** Access token promise */
        const token = get(this.options.token_name);
        if (token) {
            this.tokenExpiresAt = token.timestamp + token.expires_in;
            this.token = Promise.resolve(token);
            this.tokenResolved = true;
        }
    }

    /**
     * Authenticate against OAuth2 server
     * @param {string} username - The user's identity
     * @param {string} password - The user's password
     * @returns {object} Promise
     */
    authenticate(username, password) {
        console.debug('OAuth2Client#authenticate');

        // Trigger fetchToken with username and password
        return this.fetchToken({
            grant_type: 'password',
            username: username,
            password: password,
        });
    }

    /**
     * Does the OAuth2 client have a token?
     * @returns {bool}
     */
    hasToken() {
        console.debug('OAuth2Client#hasToken');

        return (typeof this.token === 'object');
    }

    /**
     * Is the OAuth2 client's token expired?
     * @returns {bool}
     */
    isTokenExpired() {
        console.debug('OAuth2Client#isTokenExpired');

        // Return whether token is expired (with 10 second buffer to account for system time drift)
        return (this.tokenExpiresAt - 10 <= Math.floor(Date.now() / 1000));
    }

    /**
     * Is the OAuth2 client's token resolved?
     * @returns {bool}
     */
    isTokenResolved() {
        console.debug('OAuth2Client#isTokenResolved');
        // Return whether token is resolved
        /** @todo Would be great if native Promises let us access it's state... might change to Bluebird? */
        return this.tokenResolved;
    }

    /**
     * Get existing OAuth2 token, or refresh if token expired
     * @returns {object} Promise
     */
    getToken() {
        console.debug('OAuth2Client#getToken');

        // If token exists
        if (this.hasToken()) {
            // If token is expired and resolved; fetch a new token with refresh token
            if (this.isTokenExpired() && this.isTokenResolved()) {
                return this.fetchToken({
                    grant_type: 'refresh_token',
                    refresh_token: get(this.options.token_name).refresh_token,
                })
                    .catch(err => {
                        if (this.options.onRefreshFail) {
                            this.options.onRefreshFail();
                        }

                        throw err;
                    });
            }
            // Else; return token
            else {
                return this.token;
            }
        }
        // No token
        else {
            return Promise.reject(new Error('No token'));
        }
    }

    /**
     * Fetch token from OAuth2 server
     * @param {object} options - Options for request
     * @returns {object} Promise
     */
    fetchToken(options) {
        console.debug('OAuth2Client#fetchToken');

        // Merge options
        options = extend({
            client_id: this.options.client_id,
            client_secret: this.options.client_secret,
        }, options || {});

        // Fetch token
        this.tokenResolved = false;
        this.token = fetch(this.options.url + '/access_token', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(options).toString(),
        })
            .catch((err) => {
                // Log error message
                console.error(err.message);
                // Throw new error
                throw new Error('Unable to fetch token');
            })
            .then((response) => {
                // If response is OK; return JSON
                if (response.ok) {
                    return response.json();
                }
                // Else; throw OAuth2Error
                else {
                    return response.json()
                        .then((error) => {
                            throw new OAuth2Error(error.message, error.error);
                        });
                }
            })
            .then((token) => {
                // Set token timestamp
                token.timestamp = Math.floor(Date.now() / 1000);

                // Store access token in localStorage
                set(this.options.token_name, token);

                // Set token expiry timestamp
                this.tokenExpiresAt = token.expires_in + token.timestamp;

                this.tokenResolved = true;

                return token;
            });

        return this.token;
    }

    /**
     * Clear token
     */
    clearToken() {
        console.debug('OAuth2Client#clearToken');

        // Remove access token from self
        this.token = false;

        // Remove access token
        remove(this.options.token_name);
    }

    /**
     * Wrapper for jQuery.ajax
     * @param {object} options - Options for request
     * @returns {object} Promise
     */
    $ajax(options) {
        console.debug('OAuth2Client#ajax');

        // Check for OAuth2 token
        return this.getToken()
            .then((token) => {
                // Add "Authorization" header
                options.headers = extend({}, options.headers, {
                    'Authorization': token.token_type + ' ' + token.access_token,
                });

                // Initiate ajax call
                return ajax(options);
            });
    }

    /**
     * Fetch
     * @param {*} input
     * @param {*} init
     * @return {promise}
     */
    fetch(input, init) {
        console.debug('OAuth2Client#fetch');

        return this.getToken()
            .then((token) => {
                // Add Authorization header
                init.headers = extend({}, init.headers, {
                    'Authorization': token.token_type + ' ' + token.access_token,
                });

                return fetch(input, init)
                    .catch(() => {
                        alert('Connection error.');
                        throw new Error('Connection error');
                    });
            });
    }

    fetchJson(input, init) {
        console.debug('OAuth2Client#fetchJson');

        init.headers = _.extend({}, init.headers, {
            'Accept': 'application/json',
        });

        return this.fetch(input, init)
            .then((response) => {
                // If response is OK; return JSON
                if (response.ok) {
                    return response.json();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            });
    }

    /**
     * Download file using fetch
     * @param {object} xhrOptions
     * @param {object} options
     * @return {promise}
     */
    download(input, init, options = {}) {
        console.debug('OAuth2Client#download');

        // Proxy to fetch
        return this.fetch(input, init)
            .then((response) => {
                // If response is OK; return blob
                if (response.ok) {

                    // If filename doesn't exists, set filename
                    if (!options.filename) {
                        // Get filename from headers
                        const filename = response.headers.get("Content-Disposition").split('filename=')[1].split(';')[0];
                        options.filename = filename;
                    }

                    return response.blob();
                }
                // Else; return rejected promise
                else {
                    return response.json()
                        .then((error) => Promise.reject(error));
                }
            })
            .then((blob) => {
                // If IE/Edge
                if (window.navigator && window.navigator.msSaveBlob) {
                    // Save blob (easy!)
                    window.navigator.msSaveBlob(blob, options.filename);
                }
                // Else Chrome/Firefox/Safari
                else {
                    // Create blob URL
                    const url = window.URL.createObjectURL(blob);

                    // Create temporary <a> element
                    const tmpAnchor = document.createElement('a');
                    document.body.appendChild(tmpAnchor);

                    // Set download filename
                    tmpAnchor.download = options.filename;

                    // Set URL
                    tmpAnchor.href = url;

                    // Trigger click
                    tmpAnchor.click();

                    // Remove temporary <a> element
                    document.body.removeChild(tmpAnchor);

                    // Remove blob URL
                    window.URL.revokeObjectURL(url);
                }
            });
    }

    /**
     * Promisified XHR
     * @param {object} options
     * @return {promise}
     */
    xhrPromise(options) {
        options = extend({
            method: 'GET',
            data: null,
        }, options);

        return new Promise((resolve, reject) => {
            console.debug('OAuth2Client#xhr');

            const xhr = new XMLHttpRequest();

            xhr.addEventListener('load', function () {
                // If status is 2xx; resolve
                if (xhr.status.toString()[0] === '2') {
                    resolve(xhr.response);
                }
                // Else; reject
                else {
                    const error = new Error(xhr.response.message || xhr.response.error);
                    error.name = xhr.response.name || xhr.response.error;
                    reject(error);
                }
            });

            xhr.addEventListener('error', function () {
                // Create error and reject promise
                reject(new Error('Error connecting to server'));
            });

            xhr.addEventListener('abort', function () {
                // Create error and reject promise
                const error = new Error('Upload cancelled');
                error.name = 'Abort';
                reject(error);
            });

            // If progress callback provided; attach to progress event
            if (options.progressCallback instanceof Function) {
                xhr.addEventListener('progress', options.progressCallback);
            }

            // If upload progress callback provided; attach to upload progress event
            if (options.uploadProgressCallback instanceof Function) {
                xhr.upload.addEventListener('progress', options.uploadProgressCallback);
            }

            // Open
            xhr.open(options.method, options.url, true);

            // Set headers
            for (let key in options.headers) {
                xhr.setRequestHeader(key, options.headers[key]);
            }

            // Set response type
            if (options.responseType) {
                xhr.responseType = options.responseType;
            }

            // Send request
            xhr.send(options.data);
        });
    }

    /**
     * XHR with OAuth2 token
     * @param {object} options
     */
    xhr(options) {
        // Get OAuth2 token
        return this.getToken()
            .then((token) => {
                // Add authorization headers
                options.headers = extend({}, options.headers, {
                    'Authorization': token.token_type + ' ' + token.access_token,
                });
                return this.xhrPromise(options);
            });
    }

    /**
     * Upload file using XHR
     * @param {string} field name
     * @param {File} file
     * @param {object} xhrOptions
     * @param {object} options
     * @return Promise
     */
    xhrUpload(name, file, xhrOptions, options) {
        options || (options = {});

        // Make sure file is File object
        if (!(file instanceof File)) {
            throw 'Parameter "file" is not instance of File';
        }

        // If size limit specified and size > limit; reject
        if (options.sizeLimit && file.size > options.sizeLimit) {
            throw 'File too large';
        }

        // Set response type to JSON
        xhrOptions.responseType = 'json';

        // Create FormData and append file
        xhrOptions.data = new FormData;
        xhrOptions.data.append(name, file);

        // Add accept header
        xhrOptions.headers = extend({}, xhrOptions.headers, {
            'Accept': 'application/json',
        });

        //
        return this.xhr(xhrOptions);
    }
}

class OAuth2Error extends Error {
    constructor(message, error) {
        // Call Error class contructor
        super(message);
        this.name = 'OAuth2Error';

        // Assign code property
        this.error = error;
    }
}
