var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>\n                    <a href=\"#property/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n                </td>\n                <td class=\"d-none d-md-table-cell w-50\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/propertyAddress.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"propertyAddress","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":44}}}))
    + "\n                </td>\n            </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n                <td>"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"account.no_properties",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":49}}}))
    + "</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card mb-3\">\n    <div class=\"card-body\">\n        <h2 class=\"card-title h4\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.properties",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":60}}}))
    + "</h2>\n\n        <table class=\"table mb-0\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"properties") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":19,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n    </div>\n</div>\n";
},"useData":true});