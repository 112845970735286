import { View } from 'backbone';
import getSpinner from '../../spinner';
import template from '../templates/dashboard.hbs';

const spinner = getSpinner();

export default View.extend({

    className: 'container',

    template: template,

    events: {},

    initialize: function() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);

        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function() {
                spinner.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function() {
                spinner.stop();
        });
    },

    render: function() {
        console.debug('Index#render');

        this.el.innerHTML = this.template(this.model.toJSON());

        return this;
    },

});