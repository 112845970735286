import { isFunction } from 'underscore';
import { View } from 'backbone';
import yuyan from '../../yuyan';
import template from '../templates/dynamic_select.hbs';

export default View.extend({

    tagName: 'select',

    className: 'form-control',

    template: template,

    selected: null,

    mapper: null,

    initialize: function(options) {
        this.selected = options.selected;
        this.mapper = options.mapper;

        // When collection syncs; render
        this.listenTo(this.model, 'sync', this.render);
    },

    render: function() {
        console.debug('DynamicSelect#render');

        // If mapper is NOT a function
        if (!isFunction(this.mapper)) {
            throw "Property 'mapper' is not a function";
        }

        // Map collection to label+value pairs using custom mapper function
        const options = this.model.map(this.mapper);

        // Add initial option
        options.unshift({
            label: yuyan.t('ui.please_select'),
            value: '',
        });

        this.el.innerHTML = this.template({
            options: options,
        });

        // Set selected value of element
        if (this.selected) {
            this.el.value = this.selected;
        }

        return this;
    },

});