var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>\n    "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.account_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":34}}}))
    + " <span class=\"text-nowrap\">931 "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"deposit_account_number") : stack1), depth0))
    + "</span>\n</h1>\n\n<hr>\n\n<div class=\"row\">\n    <div class=\"col-md-5 mb-3\">\n        <div class=\"card\">\n            <div class=\"card-body text-center\">\n                <h2 class=\"h6 card-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.balance_current",{"name":"t","hash":{"since":((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"balance_period") : stack1)},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":102}}}))
    + "</h2>\n                <span class=\"float-left\" style=\"font-size:24px\">&yen;</span>\n                <span class=\""
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"balance") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":13,"column":29},"end":{"line":13,"column":60}}}))
    + "\" style=\"font-size:24px\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"balance") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":53}}}))
    + "\n                </span>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-md-7 mb-3\">\n        <dl class=\"row mb-0\">\n            <dt class=\"col-sm-4 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.owner_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":47},"end":{"line":22,"column":73}}}))
    + "</dt>\n            <dd class=\"col-sm-8 mb-0\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"owner") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</dd>\n            <dt class=\"col-sm-4 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.bank",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":47},"end":{"line":24,"column":67}}}))
    + "</dt>\n            <dd class=\"col-sm-8 mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.smbc_tokyodaiichi",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":38},"end":{"line":25,"column":71}}}))
    + "</dd>\n        </dl>\n    </div>\n</div>\n\n<div id=\"properties\"></div>\n\n<canvas class=\"chart my-3 d-none <!--d-md-block-->\" style=\"width: 100%; height: 400px\"></canvas>\n\n<div id=\"statements\"></div>";
},"useData":true});