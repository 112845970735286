import { Collection } from 'backbone';
import config from '../../config';
import ownerModel from '../models/owner';

export default class OwnersCollection extends Collection {

    preinitialize() {
        this.url = config.api.url + '/owners';

        // Reference to this collection's model.
        this.model = ownerModel;
    }

    parse(response) {
        return response.data;
    }

}
