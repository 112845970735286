import { history, View } from 'backbone';
import '../../../../css/owner.css';
import getSpinner from '../../spinner';
import OwnersCollection from '../collections/owners';
import { setIsMultipleOwner } from '../isMultipleOwner';
import { setOwnerId } from '../ownerId';
import errorTemplate from '../templates/error.hbs';
import template from '../templates/index.hbs';
import itemTemplate from '../templates/item.hbs';
import loadingTemplate from '../templates/loading.hbs';

const spinner = getSpinner();

export default View.extend({

    className: 'container',

    collection: new OwnersCollection(),

    events: {
        'input [name="name"]': 'handleNameChange',
        'click [data-owner]': 'handleOwnerClick'
    },

    initialize: function () {
        // When collection updates; render
        this.listenTo(this.collection, 'update reset', this.handleCollectionUpdate);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            spinner.spin(this.el.querySelector('.card-body'));
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function () {
            spinner.stop();
        });
    },

    render: function () {
        console.debug('OwnerIndexView#render');

        this.el.innerHTML = loadingTemplate();

        this.collection.fetch({
            reset: true,
        });

        return this;
    },

    renderList: function (owners) {
        console.debug('OwnerIndexView#renderList');

        this.el.querySelector('#ownerList').innerHTML = itemTemplate({
            owners
        });

        return this;
    },

    renderError: function () {
        console.debug('OwnerIndexView#renderError');

        this.el.innerHTML = errorTemplate();

        return this;
    },

    handleCollectionUpdate: function () {
        console.debug('OwnerIndexView#handleCollectionUpdate');

        if (this.collection.length === 0) {
            setIsMultipleOwner(false);

            this.renderError();
        } else if (this.collection.length === 1) {
            setIsMultipleOwner(false);

            setOwnerId(this.collection.at(0).id);

            // Navigate to dashboard
            history.navigate('/dashboard', { trigger: true });
        } else {
            setIsMultipleOwner(true);

            // Render index template
            this.el.innerHTML = template();

            this.renderList(this.collection.toJSON());
        }
    },

    handleNameChange: function (e) {
        console.debug('OwnerIndexView#handleNameChange');

        const value = e.currentTarget.value.toLowerCase();
        const owners = this.collection.toJSON();

        this.renderList(owners.filter(owner => owner.name.toLowerCase().includes(value)));
    },

    handleOwnerClick: function (e) {
        console.debug('OwnerIndexView#handleOwnerClick');

        e.preventDefault();

        setOwnerId(e.currentTarget.dataset.owner);

        // Navigate to dashboard
        history.navigate('/dashboard', { trigger: true });
    }
});
