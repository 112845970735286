var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"container\">\n    <a class=\"navbar-brand\" href=\"#dashboard\">\n        <img alt=\"HJ\" src=\"/img/logo_hj.svg\" width=\"30\" height=\"30\">\n    </a>\n\n    <button class=\"navbar-toggler\" type=\"button\" data-toggle=\"collapse\" data-target=\"#nav\" aria-controls=\"nav\"\n        aria-expanded=\"false\" aria-label=\"Toggle navigation\">\n        <span class=\"navbar-toggler-icon\"></span>\n    </button>\n\n    <div id=\"nav\" class=\"navbar-collapse collapse\" aria-expanded=\"false\">\n        <ul class=\"navbar-nav ml-auto\">\n            <li class=\"nav-item\"><a class=\"nav-link\" href=\"#dashboard\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.dashboard",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":71},"end":{"line":13,"column":92}}}))
    + "</a></li>\n            <li class=\"nav-item\"><a class=\"nav-link\" href=\"#property\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.properties",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":70},"end":{"line":14,"column":92}}}))
    + "</a></li>\n            <li class=\"nav-item\"><a class=\"nav-link\" href=\"#account\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.accounts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":69},"end":{"line":15,"column":89}}}))
    + "</a></li>\n            <li class=\"nav-item\"><a class=\"nav-link\" href=\"#cashflow\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.cashflow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":70},"end":{"line":16,"column":90}}}))
    + "</a></li>\n            <li class=\"nav-item\"><a class=\"nav-link\" href=\"#help\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.help",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":82}}}))
    + "</a></li>\n            <li class=\"nav-item dropdown\">\n                <a class=\"nav-link d-none d-md-block\" href=\"#\" role=\"button\" data-toggle=\"dropdown\" aria-haspopup=\"true\"\n                    aria-expanded=\"false\">\n                    <svg width=\"23\" height=\"23\" viewBox=\"0 0 16 16\" class=\"bi bi-person-circle\" fill=\"currentColor\"\n                        xmlns=\"http://www.w3.org/2000/svg\">\n                        <path\n                            d=\"M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z\" />\n                        <path fill-rule=\"evenodd\" d=\"M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z\" />\n                        <path fill-rule=\"evenodd\"\n                            d=\"M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z\" />\n                    </svg>\n                </a>\n                <a class=\"nav-link dropdown-toggle d-md-none\" href=\"#\" role=\"button\" data-toggle=\"dropdown\"\n                    aria-haspopup=\"true\" aria-expanded=\"false\">\n                    Profile\n                </a>\n                <div class=\"dropdown-menu dropdown-menu-right\">\n                    <a class=\"dropdown-item\" href=\"#\" data-part=\"multipleOwner\">Switch Owner</a>\n                    <div class=\"dropdown-divider\" data-part=\"multipleOwner\"></div>\n                    <a class=\"dropdown-item\" href=\"#logout\" data-action=\"logout\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"nav.logout",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":81},"end":{"line":37,"column":99}}}))
    + "</a>\n                </div>\n            </li>\n        </ul>\n    </div>\n</div>";
},"useData":true});