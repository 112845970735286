var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal-dialog modal-sm modal-dialog-centered\" role=\"document\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <h5 class=\"modal-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.confirm",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":66}}}))
    + "</h5>\n        </div>\n\n        <div class=\"modal-body\">\n            <p class=\"mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.are_you_sure",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":63}}}))
    + "</p>\n        </div>\n\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"btn btn-secondary\" data-dismiss=\"modal\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":81},"end":{"line":12,"column":110}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-primary\" data-action=\"logout\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.logout",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":79},"end":{"line":13,"column":108}}}))
    + "</button>\n        </div>\n    </div>\n</div>";
},"useData":true});