var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"owner") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n\n<hr>\n\n<div class=\"row\">\n    <div class=\"col-md mb-3\">\n        <div class=\"card\">\n            <div class=\"card-body\">\n                <div class=\"text-center\">\n                    <h2 class=\"h6 card-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"dashboard.properties",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":46},"end":{"line":10,"column":74}}}))
    + "</h2>\n                    <span style=\"font-size:32px\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"count_properties") : depth0), depth0))
    + "</span>\n                </div>\n\n                <div class=\"text-right\">\n                    <a href=\"#property\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"dashboard.view_more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":40},"end":{"line":15,"column":67}}}))
    + "</a>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-md mb-3\">\n        <div class=\"card\">\n            <div class=\"card-body\">\n                <div class=\"text-center\">\n                    <h2 class=\"h6 card-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"dashboard.total_account_balance",{"name":"t","hash":{"since":(depth0 != null ? lookupProperty(depth0,"balance_period") : depth0)},"data":data,"loc":{"start":{"line":25,"column":46},"end":{"line":25,"column":106}}}))
    + "</h2>\n                    <span class=\"float-left\" style=\"font-size:32px\">&yen;</span>\n                    <span class=\""
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_balance") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":27,"column":33},"end":{"line":27,"column":62}}}))
    + "\"\n                        style=\"font-size:32px\">"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_balance") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":28,"column":47},"end":{"line":28,"column":78}}}))
    + "</span>\n                </div>\n\n                <div class=\"text-right\">\n                    <a href=\"#account\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"dashboard.view_more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":39},"end":{"line":32,"column":66}}}))
    + "</a>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-md mb-3\">\n        <div class=\"card\">\n            <div class=\"card-body\">\n                <div class=\"text-center\">\n                    <h2 class=\"h6 card-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"dashboard.total_credit",{"name":"t","hash":{"for":(depth0 != null ? lookupProperty(depth0,"balance_period") : depth0)},"data":data,"loc":{"start":{"line":44,"column":46},"end":{"line":44,"column":95}}}))
    + "</h2>\n                    <span class=\"float-left\" style=\"font-size:32px\">&yen;</span>\n                    <span class=\""
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_credit") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":46,"column":33},"end":{"line":46,"column":61}}}))
    + "\"\n                        style=\"font-size:32px\">"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_credit") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":47,"column":47},"end":{"line":47,"column":77}}}))
    + "</span>\n                </div>\n            </div>\n\n        </div>\n    </div>\n\n    <div class=\"col-md mb-3\">\n        <div class=\"card\">\n            <div class=\"card-body\">\n                <div class=\"text-center\">\n                    <h2 class=\"h6 card-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"dashboard.total_debit",{"name":"t","hash":{"for":(depth0 != null ? lookupProperty(depth0,"balance_period") : depth0)},"data":data,"loc":{"start":{"line":58,"column":46},"end":{"line":58,"column":94}}}))
    + "</h2>\n                    <span class=\"float-left\" style=\"font-size:32px\">&yen;</span>\n                    <span class=\""
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_debit") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":60,"column":33},"end":{"line":60,"column":60}}}))
    + "\"\n                        style=\"font-size:32px\">"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_debit") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":61,"column":47},"end":{"line":61,"column":76}}}))
    + "</span>\n                </div>\n            </div>\n\n        </div>\n    </div>\n\n    <div class=\"col-md mb-3\">\n        <div class=\"card\">\n            <div class=\"card-body\">\n                <div class=\"text-center\">\n                    <h2 class=\"h6 card-title\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"dashboard.total_net",{"name":"t","hash":{"for":(depth0 != null ? lookupProperty(depth0,"balance_period") : depth0)},"data":data,"loc":{"start":{"line":72,"column":46},"end":{"line":72,"column":92}}}))
    + "</h2>\n                    <span class=\"float-left\" style=\"font-size:32px\">&yen;</span>\n                    <span class=\""
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_net") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":74,"column":33},"end":{"line":74,"column":58}}}))
    + "\" style=\"font-size:32px\">"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"total_net") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":74,"column":83},"end":{"line":74,"column":110}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});