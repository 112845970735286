import { View } from 'backbone';
import template from '../templates/help.hbs';

export default View.extend({

    className: 'container',

    template: template,

    // events: {},

    initialize: function() {
        //
    },

    render: function() {
        console.debug('Index#render');

        this.el.innerHTML = this.template();

        return this;
    },

});