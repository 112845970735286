import { View } from 'backbone';
import numeral from 'numeral';
import Chart from 'chart';
import getSpinner from '../../spinner';
import yuyan from '../../yuyan';
import template from '../templates/detail.hbs';

const spinner = getSpinner();

export default View.extend({

    className: 'container',

    template: template,

    // events: {},

    initialize: function () {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);

        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            spinner.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            spinner.stop();
        });
    },

    render: function () {
        console.debug('Detail#render');

        this.el.innerHTML = this.template({
            property: this.model.toJSON(),
        });

        const geocoder = new google.maps.Geocoder();

        // Get geocode for address
        geocoder.geocode({
            address: this.model.toJSON().address_ja,
        }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                // Create map with geocode result, and attach to #map
                const map = new google.maps.Map(document.getElementById('map'), {
                    center: results[0].geometry.location,
                    zoom: 17,
                    scrollwheel: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                });

                // Add marker to map
                new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                });
            } else {
                console.error('Geocode could not be obtained', status);
            }
        });

        this.renderChart();

        return this;
    },

    renderChart: function () {
        console.debug('Detail#renderChart');

        const blue = tinycolor('#0056b3aa').brighten();
        const green = tinycolor('#28a745aa').brighten();
        const red = tinycolor('#dc3545aa').brighten();

        const collection = this.model.toJSON().monthly_cashflow;

        collection.map(cashflow => numeral(cashflow.net).format('0,0'));

        const chartData = {
            labels: collection.map(cashflow => cashflow.period),
            datasets: [
                // Net
                {
                    type: 'line',
                    label: yuyan.t('property.net'),
                    data: collection.map(cashflow => cashflow.net),
                    fill: false,
                    lineTension: 0,
                    borderColor: blue.toRgbString(),
                    backgroundColor: blue.toRgbString(),
                    hoverBackgroundColor: blue.brighten().toRgbString(),
                },
                // Credit
                {
                    label: yuyan.t('property.credit'),
                    data: collection.map(cashflow => cashflow.credit),
                    borderColor: green.toRgbString(),
                    backgroundColor: green.toRgbString(),
                    hoverBackgroundColor: green.brighten().toRgbString(),
                },
                // Debit
                {
                    label: yuyan.t('property.debit'),
                    data: collection.map(cashflow => cashflow.debit),
                    borderColor: red.toRgbString(),
                    backgroundColor: red.toRgbString(),
                    hoverBackgroundColor: red.brighten().toRgbString(),
                }
            ]
        };

        const ctx = this.el.querySelector('canvas.chart');
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: chartData,
            options: {
                easing: 'easeOutQuint',
                legend: {
                    position: 'bottom',
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            callback: (value) => {
                                return numeral(value).format('0,0');
                            },
                        },
                    }],
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem) => {
                            return numeral(tooltipItem.value).format('0,0');
                        },
                    },
                },
            },
        });
    }

});