import { Model } from 'backbone';

export default class CashflowCriteriaModel extends Model {

    preinitialize() {
        this.defaults = function () {
            return {
                order_by: 'transaction_date',
                inclusions: ['account', 'property']
            };
        }
    }

}