var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":26}}}))
    + "</h1>\n\n<hr>\n\n<form class=\"form-horizontal\">\n    <div class=\"row\">\n        <div class=\"col-md-5\">\n            <div class=\"form-group row\">\n                <label for=\"field-date_range\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.date_range",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":92},"end":{"line":9,"column":119}}}))
    + "</label>\n                <div class=\"col-md-8\">\n                    <select id=\"field-date_range\" class=\"form-control\">\n                        <option value=\"last-month\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.last_month",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":78}}}))
    + "</option>\n                        <option value=\"last-3-months\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.last_3_months",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":84}}}))
    + "</option>\n                        <option value=\"custom\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":70}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n\n            <div class=\"form-group row\">\n                <label for=\"field-date_from\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.date_from",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":91},"end":{"line":20,"column":117}}}))
    + "</label>\n                <div class=\"col-md-8\">\n                    <input id=\"field-date_from\" name=\"date_from\" type=\"date\" class=\"form-control\" readonly>\n                </div>\n            </div>\n\n            <div class=\"form-group row\">\n                <label for=\"field-date_to\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.date_to",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":89},"end":{"line":27,"column":113}}}))
    + "</label>\n                <div class=\"col-md-8\">\n                    <input id=\"field-date_to\" name=\"date_to\" type=\"date\" class=\"form-control\" readonly>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-md-7\">\n            <div class=\"form-group row\">\n                <label for=\"field-type\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":86},"end":{"line":36,"column":107}}}))
    + "</label>\n                <div class=\"col-md-8\">\n                    <select id=\"field-type\" name=\"type\" class=\"form-control\">\n                        <option value=\"\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.all_transactions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":41},"end":{"line":39,"column":74}}}))
    + "</option>\n                        <option value=\"credit\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.credit_only",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":47},"end":{"line":40,"column":75}}}))
    + "</option>\n                        <option value=\"debit\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.debit_only",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":46},"end":{"line":41,"column":73}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n\n            <div id=\"account-select\" class=\"form-group row d-none\">\n                <label for=\"field-account\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":89},"end":{"line":47,"column":113}}}))
    + "</label>\n                <div id=\"cntr-field-account\" class=\"col-md-8\">\n                    <!-- SELECT -->\n                </div>\n            </div>\n\n            <div class=\"form-group row\">\n                <label for=\"field-property\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.property",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":90},"end":{"line":54,"column":115}}}))
    + "</label>\n                <div id=\"cntr-field-property\" class=\"col-md-8\">\n                    <!-- SELECT -->\n                </div>\n                <div id=\"cashflow-help-text\" class=\"col-md-8 offset-md-4 d-none\">\n                    <p id=\"help-account_property\" class=\"form-text text-muted mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.help_text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":84},"end":{"line":59,"column":110}}}))
    + "</p>\n                </div>\n            </div>\n\n            <div class=\"form-group row room-select d-none\">\n                <label for=\"field-rooms\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.room_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":87},"end":{"line":64,"column":115}}}))
    + "</label>\n                <div id=\"cntr-field-rooms\" class=\"col-md-8\">\n                    <!-- SELECT -->\n                </div>\n            </div>\n\n            <div class=\"form-group row\">\n                <label for=\"field-description\" class=\"col-md-4 col-form-label text-md-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":93},"end":{"line":71,"column":121}}}))
    + "</label>\n                <div class=\"col-md-8\">\n                    <input id=\"field-description\" name=\"description\" type=\"text\" class=\"form-control\">\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"form-group\">\n        <div class=\"d-flex justify-content-between\">\n            <div>\n                <a id=\"btn-export\" class=\"btn btn-outline-primary d-none\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.export",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":74},"end":{"line":82,"column":97}}}))
    + "</a>\n            </div>\n            <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":58},"end":{"line":84,"column":81}}}))
    + "</button>\n        </div>\n    </div>\n</form>\n\n<div id=\"results\" style=\"min-height:60px;position:relative\"></div>\n";
},"useData":true});