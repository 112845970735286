export default function(number) {
    // Cast as number
    // number = Number(number);

    // Return appropriate bootstrap class
    if (number > 0) {
        return 'text-success';
    } else if (number < 0) {
        return 'text-danger';
    } else {
        return '';
    }
}