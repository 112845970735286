import { Collection } from 'backbone';
import config from '../../config';
import PropertyModel from '../models/property';

export default class RoomsCollection extends Collection {

    preinitialize() {
        this.placeId = null;

        this.url = () => config.api.url + '/properties/' + this.placeId + '/rooms';

        // Reference to this collection's model.
        this.model = PropertyModel;
    }

    initialize(models, options = {}) {
        this.placeId = options.placeId || null;
    }

}