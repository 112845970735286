var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form class=\"form-login mx-auto\">\n\n    <div class=\"text-center\">\n        <img src=\"/img/logo_hj.svg\" alt=\"Housing Japan\" width=\"180\" height=\"180\" style=\"margin: 30px 0 20px\">\n    </div>\n\n    <h2 class=\"text-center\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":56}}}))
    + "</h2>\n\n    <div class=\"form-group\">\n        <label for=\"field-email\" class=\"sr-only\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.email_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":85}}}))
    + "</label>\n        <input type=\"email\" id=\"field-email\" class=\"form-control form-control-lg\" placeholder=\""
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.email_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":95},"end":{"line":11,"column":131}}}))
    + "\" required autofocus>\n    </div>\n\n    <div class=\"form-group\">\n        <label for=\"field-password\" class=\"sr-only\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":52},"end":{"line":15,"column":83}}}))
    + "</label>\n        <input type=\"password\" id=\"field-password\" class=\"form-control form-control-lg\" placeholder=\""
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":101},"end":{"line":16,"column":132}}}))
    + "\" required>\n    </div>\n\n    <div class=\"alert alert-danger d-none\" role=\"alert\"></div>\n\n    <div class=\"form-group\">\n        <button type=\"submit\" class=\"btn btn-lg btn-primary btn-block\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"authentication.login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":71},"end":{"line":22,"column":99}}}))
    + "</button>\n    </div>\n\n</form>\n";
},"useData":true});