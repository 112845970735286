/**
 * Local storage wrapper, which includes expiry option
 */

/**
 * Assert key
 *
 * @param {string} key - Key to validate
 * @returns {void}
 */
function assertKey(key) {

    // Assert string
    if (typeof key !== 'string') {
        throw "Parameter 'key' must be a string";
    }
    // Assert not empty
    if (key.length === 0) {
        throw "Parameter 'key' must not be empty";
    }

}

/**
 * Assert value
 *
 * @param {mixed} value - Value to validate
 * @returns {void}
 */
function assertValue(value) {

    // Assert not undefined
    if (typeof value === 'undefined') {
        throw "Parameter 'value' must be specified";
    }

}


/**
 * Set storage item
 *
 * @param {string} key - Storage key
 * @param {mixed} value - Storage payload
 * @param {object} options - Storage options
 * @returns {void}
 */
export function set(key, value, options) {

    assertKey(key);

    assertValue(value);

    // Validate options
    options = options || {};
    if (typeof options !== 'object') {
        throw "Parameter 'options' must be an object";
    }

    // Create item object with data key containing desired payload
    const item = {
        data: value,
    };

    // Check for expiry option
    const expiry = options.expiry || false;
    if (expiry) {
        // Set special expiry key (UNIX timestamp, in milliseconds)
        item.expiry = (new Date).getTime() + (expiry * 1000);
    }

    // Check for idle expiry option
    const idleExpiry = options.idleExpiry || false;
    if (idleExpiry) {
        // Set the timeout (in milliseconds) and last access time (UNIX timestamp)
        item.idleExpiry = {
            timeout: (idleExpiry * 1000),
            lastAccess: (new Date).getTime(),
        };
    }

    // Stringify item object and store in localStorage
    localStorage.setItem(key, JSON.stringify(item));

}

/**
 * Get storage item
 *
 * @param {string} key - Storage key
 * @returns {mixed} - Storage payload
 */
export function get(key) {

    assertKey(key);

    // Get item from localStorage and parse JSON
    const item = JSON.parse(localStorage.getItem(key));
    if (!item) {
        return false;
    }

    // Check for expiry key
    const expiry = item.expiry || false;
    // If expiry is before current time
    if (expiry && expiry < (new Date).getTime()) {
        // Remove key and return false
        remove(key);
        return false;
    }

    // Check for idleExpiry key
    const idleExpiry = item.idleExpiry || false;
    if (idleExpiry) {
        // Get current time
        const now = (new Date).getTime();
        // If lastAccess + timeout is after current time
        if (idleExpiry.lastAccess + idleExpiry.timeout > now) {
            // Update lastAccess
            item.idleExpiry.lastAccess = now;
            localStorage.setItem(key, JSON.stringify(item));
        } else {
            // Remove key and return false
            localStorage.removeItem(key);
            return false;
        }
    }

    // Return data payload
    return item.data;

}

/**
 * Remove storage item
 *
 * @param {string} key - Storage key
 * @returns {void}
 */
export function remove(key) {

    assertKey(key);

    // Remove key from localStorage
    localStorage.removeItem(key);

}