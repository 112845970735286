import { Model } from 'backbone';
import config from '../../config';

export default class OwnerModel extends Model {

    preinitialize() {
        this.urlRoot = config.portal_api.url + '/owners';
    }

    parse(response) {
        if (response.data) {
            return response.data;
        } else {
            return response;
        }
    }

}