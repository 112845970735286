var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td class=\"text-nowrap\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"transaction_date") : depth0), depth0))
    + "</td>\n                <td><a href=\"#property/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a></td>\n                <td><a href=\"#account/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">931 "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"deposit_account_number") : stack1), depth0))
    + "</a></td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"room_number") : depth0), depth0))
    + "</td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</td>\n                <td class=\"text-nowrap "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":24,"column":39},"end":{"line":24,"column":66}}}))
    + "\">&yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":24,"column":73},"end":{"line":24,"column":102}}}))
    + "</td>\n                <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"freee_receipt_ids") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":28,"column":29}}})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <button class=\"btn btn-link py-0\" data-action=\"download\" data-receipt=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n                <td colspan=\"7\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"cashflow.no_transactions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":32},"end":{"line":33,"column":64}}}))
    + "</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n\n    <table class=\"table table-striped\">\n        <thead>\n            <tr class=\"text-nowrap\">\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":41}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.property",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":45}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":44}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.room_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":48}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":48}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.amount",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":43}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":45}}}))
    + "</th>\n            </tr>\n        </thead>\n\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"cashflow") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":35,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n\n</div>";
},"useData":true});