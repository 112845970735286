import { Model } from 'backbone';
import config from '../../config';

export default class PropertyModel extends Model {

    preinitialize() {
        this.urlRoot = config.api.url + '/properties';

        this.idAttribute = 'code';
    }

}