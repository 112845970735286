import { Model } from 'backbone';
import config from '../../config';

export default class CashflowModel extends Model {

    preinitialize() {
        this.urlRoot = config.api.url + '/cashflow';
    }

    parse(response) {
        if (response.data) {
            return response.data;
        } else {
            return response;
        }
    }

}