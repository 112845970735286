import { View } from 'backbone';
import config from '../../config';
import oauth2client from '../../oauth2-client';
import { getOwnerId } from '../../owner/ownerId';
import getSpinner from '../../spinner';
import yuyan from '../../yuyan';
import template from '../templates/statement.hbs';

const spinner = getSpinner();

export default View.extend({

    className: 'container',

    template: template,

    events: {
        'click [data-action="download"]': 'handleDownloadClick',
    },

    initialize: function () {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);

        // When model starts request; start spinner
        this.listenTo(this.model, 'request', function () {
            spinner.spin(this.el);
        });

        // When model finishes request; stop spinner
        this.listenTo(this.model, 'sync error', function () {
            spinner.stop();
        });
    },

    render: function () {
        console.debug('Statement#render');

        this.el.innerHTML = this.template({
            statement: this.model.toJSON()
        });

        return this;
    },

    handleDownloadClick: function (e) {
        console.debug('Statement#handleDownloadClick');

        const dataset = e.currentTarget.dataset;

        if (dataset.download) {
            this.downloadAttachment(dataset.download);
        } else if (dataset.receipt) {
            this.downloadReceipt(dataset.receipt);
        }
    },

    downloadAttachment: function (attachmentType) {
        console.debug('Statement#downloadAttachment');

        const account = this.model.get('account');

        if (attachmentType === 'xlsx') {
            // Download XLSX version of statement
            oauth2client.download(
                this.model.url() + '?owner_id=' + encodeURIComponent(getOwnerId()) + '&language=' + yuyan.language(),
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }
                },
                { filename: '931 ' + account.deposit_account_number + ' - ' + this.model.get('period') + '.' + attachmentType }
            );
        } else if (attachmentType === 'pdf') {
            // Download PDF attachment for statement
            oauth2client.download(
                this.model.url() + '/' + attachmentType + '?owner_id=' + encodeURIComponent(getOwnerId()),
                { method: 'GET' },
                { filename: '931 ' + account.deposit_account_number + ' - ' + this.model.get('period') + '.' + attachmentType }
            );
        }
    },

    downloadReceipt: function (receiptId) {
        console.debug('Statement#downloadReceipt');

        oauth2client.download(
            config.api.url + '/cashflow-receipts/' + receiptId + '?owner_id=' + encodeURIComponent(getOwnerId()),
            { method: 'GET' }
        );
    }

});