import { View } from 'backbone';
import LogoutModal from '../../authentication/views/logout_modal';
import { getIsMultipleOwner } from '../../owner/isMultipleOwner';
import template from '../templates/nav.hbs';
import logoutOnlyTemplate from '../templates/nav_logout_only.hbs';

export default View.extend({

    template: template,

    logoutOnlyTemplate: logoutOnlyTemplate,

    events: {
        'click [data-action="logout"]': 'renderLogoutModal',
    },

    initialize: function () {
        this.subviews = {
            logoutModal: new LogoutModal()
        };
    },

    render: function (options = {}) {
        console.debug('Nav#render');

        if (options.isLogoutOnly) {
            this.el.innerHTML = this.logoutOnlyTemplate();
        } else {
            this.el.innerHTML = this.template();
        }

        if (!getIsMultipleOwner()) {
            this.el.querySelectorAll('[data-part="multipleOwner"]').forEach(el => {
                el.classList.add('d-none');
            });
        }

        return this;
    },

    renderLogoutModal: function (e) {
        console.debug('Nav#renderLogoutModal');

        e.preventDefault();

        this.subviews.logoutModal.render();
    }

});