import { history } from 'backbone';
import OAuth2Client from '../libs/oauth2-client';
import config from './config';

const oauth2Client = new OAuth2Client({
    url: config.auth.url,
    client_id: config.auth.client_id,
    client_secret: config.auth.client_secret,
    token_name: 'hjt',
    onRefreshFail
});

function onRefreshFail() {
    oauth2Client.clearToken();

    history.navigate('login', { trigger: true });
}

export default oauth2Client;