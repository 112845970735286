import { View } from 'backbone';
import { bindAll, each } from 'underscore';
import AccountsCollection from '../../account/collections/accounts';
import { getOwnerId } from '../../owner/ownerId';
import PropertiesCollection from '../../property/collections/properties';
import RoomsCollection from '../../property/collections/rooms';
import DynamicSelectView from '../../ui/views/DynamicSelect';
import CashflowsCollection from '../collections/cashflows';
import CashflowCriteria from '../models/criteria';
import CashflowResultsView from './results';
import template from '../templates/search.hbs';
import oauth2client from '../../oauth2-client';
import config from '../../config';

export default View.extend({

    className: 'container',

    template: template,

    events: {
        'submit form': 'handleSubmit',
        'change #field-property': 'handleChangeProperty',
        'change #field-account': 'handleChangeAccount',
        'change #field-date_range': 'setDateRange',
        'focus [readonly]': 'forceBlur',
        'click #btn-export': 'handleExport'
    },

    initialize: function () {
        // Functions that will trigger from events need to bind to "this"
        bindAll(this, 'handleSubmit', 'resetFieldAccount', 'resetFieldProperty', 'setDateRange', 'toggleRoomSelect', 'forceBlur');

        // Create criteria model
        this.criteria = new CashflowCriteria();

        this.placeId = null;

        // Create subviews
        this.subviews = {
            accountSelect: new DynamicSelectView({
                id: 'field-account',
                attributes: {
                    name: 'account_id',
                    'aria-describedby': 'help-account_property',
                },
                model: new AccountsCollection(),
                mapper: function (model) {
                    return {
                        label: '931 ' + model.get('deposit_account_number'),
                        value: model.id,
                    };
                },
            }),
            propertySelect: new DynamicSelectView({
                id: 'field-property',
                attributes: {
                    name: 'property_code',
                    'aria-describedby': 'help-account_property',
                },
                model: new PropertiesCollection(),
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
            }),
            roomSelect: new DynamicSelectView({
                id: 'field-rooms',
                attributes: {
                    name: 'room_number',
                    'aria-describedby': 'help-account_property',
                },
                model: new RoomsCollection(),
                mapper: function (model) {
                    return {
                        label: model.get('room_number'),
                        value: model.get('room_number'),
                    };
                },
            }),
            results: new CashflowResultsView({
                collection: new CashflowsCollection(),
                criteria: this.criteria,
            }),
        };

        // When account select subview model updates; render
        this.listenTo(this.subviews.accountSelect.model, 'update', function (collection) {
            if (collection.length > 1) {
                this.el.querySelector('#account-select').classList.remove('d-none');
                this.el.querySelector('#cashflow-help-text').classList.remove('d-none');
            }
        });
    },

    render: function () {
        console.debug('Search#render');

        this.el.innerHTML = this.template();

        // Attach account select to placeholder
        this.el.querySelector('#cntr-field-account').appendChild(this.subviews.accountSelect.el);

        // Attach property select to placeholder
        this.el.querySelector('#cntr-field-property').appendChild(this.subviews.propertySelect.el);

        // Attach room select to placeholder
        this.el.querySelector('#cntr-field-rooms').appendChild(this.subviews.roomSelect.el);

        // Trigger change of date range
        this.$el.find('#field-date_range').change();

        // Trigger fetch of models
        this.subviews.accountSelect.model.fetch({
            data: {
                owner_id: getOwnerId()
            }
        });

        this.subviews.propertySelect.model.fetch({
            data: {
                owner_code: getOwnerId()
            }
        });

        // Bind results view
        this.subviews.results.setElement(this.$el.find('#results'));

        return this;
    },

    handleSubmit: function ($e) {
        console.debug('Search#handleSubmit');

        $e.preventDefault();

        // Get defaults
        const criteria = this.criteria.defaults();

        // Apply criteria from search form
        /** @todo This function should go in a library - could be useful elsewhere */
        each($($e.currentTarget).serializeArray(), (field) => {
            // If field name already string; convert to array
            if ('string' === typeof criteria[field.name]) {
                criteria[field.name] = [criteria[field.name]];
            }

            // If field name already array; push
            if (criteria[field.name] instanceof Array) {
                criteria[field.name].push(field.value);
            }
            // Else; Add key/value pair
            else {
                criteria[field.name] = field.value;
            }
        });

        // Show export button when search is submitted
        this.showExportButton();

        // Clear existing criteria, and set new criteria
        this.criteria.clear({ silent: true }).set(criteria);
    },

    handleExport: function () {
        console.debug('Search#handleExport');

        console.log('Export');

        const requestParams = new URLSearchParams(this.criteria.toJSON());

        requestParams.append('owner_id', getOwnerId());

        console.log(requestParams);

        oauth2client.download(
            config.api.url + '/cashflow?' + requestParams,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            }
        );
    },

    handleChangeProperty: function () {
        console.debug('Search#handleChangeProperty');

        this.resetFieldAccount();
        this.toggleRoomSelect();
    },

    handleChangeAccount: function () {
        console.debug('Search#handleChangeAccount');

        this.resetFieldProperty();
        this.resetFieldRoom();
        this.hideFieldRoom();
    },

    resetFieldProperty: function () {
        console.debug('Search#resetFieldProperty');

        this.el.querySelector('#field-property').value = '';
    },

    resetFieldAccount: function () {
        console.debug('Search#resetFieldAccount');

        this.el.querySelector('#field-account').value = '';
    },

    resetFieldRoom: function () {
        console.debug('Search#resetFieldRoom');

        this.el.querySelector('#field-rooms').value = '';
    },

    hideFieldRoom: function () {
        console.debug('Search#hideFieldRoom');

        this.el.querySelector('.room-select').classList.add('d-none');
    },

    showFieldRooms: function () {
        console.debug('Search#showFieldRoom');

        this.el.querySelector('.room-select').classList.remove('d-none');
    },

    setDateRange: function ($e) {
        console.debug('Search#setDateRange');

        const val = $e.currentTarget.value;
        const fieldDateFrom = this.el.querySelector('#field-date_from');
        const fieldDateTo = this.el.querySelector('#field-date_to');

        const dateInputs = {
            fieldDateFrom,
            fieldDateTo
        }

        switch (val) {
            case 'custom':
                // Enable date fields
                fieldDateFrom.readOnly = false;
                fieldDateTo.readOnly = false;
                break;

            case 'last-month':
                // Subtract 1 month for both dateFrom and dateTo
                this.calculateDateRange(dateInputs, 1, 1);
                break;

            case 'last-3-months':
                // Subtract 3 months for dateFrom and 1 month for dateTo
                this.calculateDateRange(dateInputs, 3, 1);
                break;
        }
    },

    calculateDateRange: function (dateInputs, monthsToStartMonth, monthsToEndMonth) {
        const { fieldDateFrom, fieldDateTo } = dateInputs;

        // Disable date fields
        fieldDateFrom.readOnly = true;
        fieldDateTo.readOnly = true;

        const startMonth = moment().subtract(monthsToStartMonth, 'months');
        const endMonth = moment().subtract(monthsToEndMonth, 'months');

        const dateFrom = startMonth.startOf('month');
        const dateTo = endMonth.endOf('month');

        fieldDateFrom.value = dateFrom.format('YYYY-MM-DD');
        fieldDateTo.value = dateTo.format('YYYY-MM-DD');
    },

    /** Safari on iOS doesn't handle readonly elements properly (<= iOS 10.3) */
    forceBlur: function ($e) {
        $($e.currentTarget).trigger('blur');
    },

    toggleRoomSelect: async function () {
        const propertySelector = this.el.querySelector('#field-property');
        const propertyId = propertySelector.value;

        // If no property is selected
        if (propertyId === '') {
            this.hideFieldRoom();
        } else {
            // Set place ID on room select model
            this.subviews.roomSelect.model.placeId = propertyId;

            await this.subviews.roomSelect.model.fetch({
                data: {
                    owner_code: getOwnerId()
                }
            });

            this.showFieldRooms();
        }
    },

    showExportButton: function () {
        const exportButton = this.el.querySelector('#btn-export');

        exportButton.classList.remove('d-none');
    }
});