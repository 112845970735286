import { View } from 'backbone';
import oauth2Client from '../../oauth2-client';
import { getOwnerId } from '../../owner/ownerId';
import getSpinner from '../../spinner';
import yuyan from '../../yuyan';
import template from '../templates/statement_list.hbs';

const spinner = getSpinner();

export default View.extend({

    template: template,

    events: {
        'click [data-action="download"]': 'handleDownloadClick',
    },

    initialize: function () {
        // When collection updates; render
        this.listenTo(this.collection, 'update', this.render);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            spinner.spin(this.el);
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function () {
            spinner.stop();
        });
    },

    render: function () {
        console.debug('StatementList#render');

        this.el.innerHTML = this.template({
            statements: this.collection.toJSON(),
            accountId: this.collection.accountId,
        });

        return this;
    },

    handleDownloadClick: function (e) {
        console.debug('StatementList#handleDownloadClick');

        const buttonDataset = e.currentTarget.dataset;

        const attachmentType = buttonDataset.download;
        const period = buttonDataset.statement;

        this.downloadAttachment(attachmentType, period);
    },

    downloadAttachment: function (attachmentType, period) {
        console.debug('StatementList#downloadAttachment');

        if (attachmentType === 'xlsx') {
            // Download XLSX version of statement
            oauth2Client.download(
                this.collection.url() + '/' + period + '?owner_id=' + encodeURIComponent(getOwnerId()) + '&language=' + yuyan.language(),
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }
                },
                { filename: '931 ' + this.model.get('deposit_account_number') + ' - ' + period + '.' + attachmentType }
            );
        } else if (attachmentType === 'pdf') {
            // Download PDF attachment for statement
            oauth2Client.download(
                this.collection.url() + '/' + period + '/' + attachmentType + '?owner_id=' + encodeURIComponent(getOwnerId()),
                { method: 'GET' },
                { filename: '931 ' + this.model.get('deposit_account_number') + ' - ' + period + '.' + attachmentType }
            );
        }

    }
});