const KEY = 'isMultipleOwner';

function set(flag) {
    return localStorage.setItem(KEY, flag);
}
export { set as setIsMultipleOwner };

function get() {
    return localStorage.getItem(KEY) === "true";
}
export { get as getIsMultipleOwner };

export default { get, set };
