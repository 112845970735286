import { View } from 'backbone';
import yuyan from '../../yuyan';
import template from '../templates/footer.hbs';

export default View.extend({

    template: template,

    events: {
        'click #language-toggle a': 'changeLanguage',
    },

    initialize: function() {
        //
    },

    render: function() {
        console.debug('Footer#render');

        this.el.innerHTML = this.template({
            currentLang: yuyan.languageName(),
            supportedLangs: yuyan.supportedLanguages,
        });

        return this;
    },

    changeLanguage: function($e) {
        console.debug('Footer#changeLanguage');

        $e.preventDefault();

        // Set language to "lang" data attribute
        yuyan.language($e.currentTarget.dataset.lang);

        // Refresh page
        location.reload();
    },

});