import Yuyan from '../libs/yuyan';

// Initialize and export Yuyan
export default new Yuyan(
    {
        'en': 'English',
        'ja': '日本語',
        'zh_hant': '中文 (繁體)',
        'zh_hans': '中文 (简体)',
    },
    'en',
);