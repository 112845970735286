import { history, Router } from 'backbone';
import AccountModel from './account/models/account';
import AccountStatementModel from './account/models/statement';
import AccountDetailView from './account/views/detail';
import AccountIndexView from './account/views/index';
import AccountStatementView from './account/views/statement';
import CashflowSearchView from './cashflow/views/search';
import DashboardModel from './dashboard/models/dashboard';
import DashboardView from './dashboard/views/index';
import HelpView from './help/views/index';
import LayoutDefault from './layout/views/default';
import LayoutLogin from './layout/views/login';
import LoginView from './authentication/views/login';
import oAuth2Client from './oauth2-client';
import { getOwnerId } from './owner/ownerId';
import OwnerIndexView from './owner/views/index';
import PropertyModel from './property/models/property';
import PropertyDetailView from './property/views/detail';
import PropertyIndexView from './property/views/index';
// import User from './user';
import yuyan from './yuyan';

// Set base title and separator
const titleBase = document.title;
const titleSeparator = ' | ';

export default Router.extend({

    layout: null,

    routes: {
        '': 'routeOwners',
        'dashboard': 'routeDashboard',
        'property': 'routePropertyList',
        'property/:id': 'routePropertyDetail',
        'account': 'routeAccountList',
        'account/:id': 'routeAccountDetail',
        'account/:id/statement/:period': 'routeAccountStatement',
        'cashflow': 'routeCashflow',
        'login': 'routeLogin',
        'help': 'routeHelp',
    },

    initialize: function () {
        this.bind('route', this._trackPageview);
    },

    _trackPageview: function () {
        const url = history.getFragment();
        ga('set', 'page', '/' + url);
        ga('send', 'pageview');
    },

    execute: function (callback, args, name) {
        // If not login route and no token
        if (name !== 'routeLogin' && !oAuth2Client.hasToken()) {
            // Redirect to login
            this.navigate('login', { trigger: true });
            return false;
        }

        console.debug('Router#execute: Executing "' + name + '"');

        // Scroll to top
        window.scrollTo(0, 0);

        if (callback) callback.apply(this, args);
    },

    renderLayout: function (options = {}) {
        if (!this.layout) {
            // Create login layout
            this.layout = new LayoutDefault({
                el: document.getElementById('app'),
            });
        }
        this.layout.render(options);
    },

    routeLogin: function () {
        // Remove layout
        this.layout = null;

        // Create and render login layout
        const layout = new LayoutLogin({
            el: document.getElementById('app'),
        });
        layout.render();

        // Create login view, render, and attach to #login
        const login = new LoginView();
        $('#login').html(login.render().el);

        // Update title
        document.title = titleBase;
    },

    routeOwners: function () {
        // Render layout
        this.renderLayout({
            header: {
                isLogoutOnly: true
            }
        });

        // Create owner index view
        const ownerIndexView = new OwnerIndexView();

        // Attach to #content
        $('#content').html(ownerIndexView.render().el);

        // Update title
        document.title = yuyan.t('owner.title') + titleSeparator + titleBase;
    },

    routeDashboard: function () {
        // Render layout
        this.renderLayout();

        // Create dashboard view, with new model
        const dashboardView = new DashboardView({
            model: new DashboardModel(),
        });

        // Attach to #content
        $('#content').html(dashboardView.el);

        // Fetch model
        dashboardView.model.fetch({
            owner_id: getOwnerId()
        });

        // Update title
        document.title = yuyan.t('dashboard.title') + titleSeparator + titleBase;
    },

    routePropertyList: function () {
        // Render layout
        this.renderLayout();

        // Create property index view, render, and attach to #content
        const propertyIndex = new PropertyIndexView();
        $('#content').html(propertyIndex.render().el);

        // Update title
        document.title = yuyan.t('property.title') + titleSeparator + titleBase;
    },

    routePropertyDetail: function (code) {
        // Render layout
        this.renderLayout();

        // Create property detail view, with new model
        const propertyDetail = new PropertyDetailView({
            model: new PropertyModel({
                code,
            }),
        });

        // Attach to #content
        $('#content').html(propertyDetail.el);

        // Trigger fetch of model
        propertyDetail.model.fetch({
            data: {
                owner_code: getOwnerId(),
                inclusions: ['account', 'rooms', 'cashflow']
            }
        });

        // Update title
        document.title = yuyan.t('property.title') + titleSeparator + titleBase;
    },

    routeAccountList: function () {
        // Render layout
        this.renderLayout();

        // Create account index view, render, and attach to #content
        const accountIndex = new AccountIndexView();
        $('#content').html(accountIndex.render().el);

        // Update title
        document.title = yuyan.t('account.title') + titleSeparator + titleBase;
    },

    routeAccountDetail: function (id) {
        // Render layout
        this.renderLayout();

        // Create account detail view, with new model
        const accountDetail = new AccountDetailView({
            model: new AccountModel({
                id,
            }),
        });

        // Attach to #content
        $('#content').html(accountDetail.el);

        // Trigger fetch of model
        accountDetail.model.fetch({
            data: {
                owner_id: getOwnerId(),
                inclusions: ['owner', 'balance']
            }
        });

        // Update title
        document.title = yuyan.t('account.title') + titleSeparator + titleBase;
    },

    routeAccountStatement: function (id, period) {
        // Render layout
        this.renderLayout();

        // Create account detail view, with new model
        const accountStatement = new AccountStatementView({
            model: new AccountStatementModel({
                period: period,
            }),
        });
        accountStatement.model.accountId = id;

        // Attach to #content
        $('#content').html(accountStatement.el);

        // Trigger fetch of model
        accountStatement.model.fetch({
            data: {
                owner_id: getOwnerId()
            }
        });

        // Update title
        document.title = yuyan.t('account.title') + titleSeparator + titleBase;
    },

    routeCashflow: function () {
        // Render layout
        this.renderLayout();

        // Create cashflow search view, and attach to #content
        const cashflowSearch = new CashflowSearchView();
        $('#content').html(cashflowSearch.render().el);

        // Update title
        document.title = yuyan.t('cashflow.title') + titleSeparator + titleBase;
    },

    routeHelp: function () {
        // Render layout
        this.renderLayout();

        // Create help view, render, and attach to #content
        const view = new HelpView();
        $('#content').html(view.render().el);

        // Update title
        document.title = yuyan.t('help.title') + titleSeparator + titleBase;
    },

});