const OWNER_KEY = 'ownerId';

function set(id) {
    return localStorage.setItem(OWNER_KEY, id);
}
export { set as setOwnerId };

function get() {
    return localStorage.getItem(OWNER_KEY);
}
export { get as getOwnerId };

function remove() {
    localStorage.removeItem(OWNER_KEY);
}
export { remove as removeOwnerId };

export default {
    get, set, remove
};
