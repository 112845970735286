import { Collection } from 'backbone';
import config from '../../config';
import accountModel from '../models/account';

export default class AccountsCollection extends Collection {

    preinitialize() {
        this.url = config.api.url + '/virtual-accounts';

        // Reference to this collection's model.
        this.model = accountModel;
    }

    parse(response) {
        return response.data;
    }

}