import { Collection } from 'backbone';
import config from '../../config';
import CashflowModel from '../models/cashflow';

export default class CashflowCollection extends Collection {

    preinitialize() {
        this.url = config.api.url + '/cashflow';

        // Reference to this collection's model.
        this.model = CashflowModel;
    }

    parse(response) {
        return response.data;
    }

}