import { Collection } from 'backbone';
import config from '../../config';
import AccountStatementModel from '../models/statement';

export default class AccountStatementCollection extends Collection {

    preinitialize() {
        // Account ID
        this.accountId = null;

        this.url = () => config.api.url + '/virtual-accounts/' + this.accountId + '/statements';

        // Reference to this collection's model.
        this.model = AccountStatementModel;
    }

    initialize(models, options) {
        this.accountId = options.accountId || null;
    }

}