import { View } from 'backbone';
import getSpinner from '../../spinner';
import template from '../templates/list.hbs';

const spinner = getSpinner();

export default View.extend({

    template: template,

    // events: {},

    initialize: function() {
        // When collection updates; render
        this.listenTo(this.collection, 'update', this.render);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function() {
            spinner.spin(this.el);
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function() {
            spinner.stop();
        });
    },

    render: function() {
        console.debug('List#render');

        this.el.innerHTML = this.template({
            properties: this.collection.toJSON(),
        });

        return this;
    },

});