import { Model } from 'backbone';
import config from '../../config';

export default class AccountStatementModel extends Model {

    preinitialize() {
        // Account ID
        this.accountId = null;

        this.idAttribute = 'period';

        this.urlRoot = () => config.api.url + '/virtual-accounts/' + this.accountId + '/statements';
    }

}