import { Collection } from 'backbone';
import config from '../../config';
import PropertyModel from '../models/property';

export default class PropertiesCollection extends Collection {

    preinitialize() {
        this.url = config.api.url + '/properties';

        // Reference to this collection's model.
        this.model = PropertyModel;
    }

}