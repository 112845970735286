import { View } from 'backbone';
import { pick } from 'underscore';
import config from '../../config';
import oauth2client from '../../oauth2-client';
import { getOwnerId } from '../../owner/ownerId';
import getSpinner from '../../spinner';
import template from '../templates/results.hbs';

const spinner = getSpinner();

export default View.extend({

    template: template,

    events: {
        'click [data-action="download"]': 'handleDownloadClick',
    },

    initialize: function (options) {
        this.criteria = options.criteria || null;

        // When criteria changes; search
        this.listenTo(this.criteria, 'change', this.search);

        // When collection resets; render
        this.listenTo(this.collection, 'reset', this.render);

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function () {
            spinner.spin(this.el);
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function () {
            spinner.stop();
        });
    },

    search: function () {
        console.debug('Results#search');

        // Fetch collection
        this.collection.fetch({
            data: {
                ...pick(this.criteria.toJSON(), (value) => { return value !== '' }),
                owner_id: getOwnerId()
            },
            reset: true,
        });
    },

    render: function () {
        console.debug('Results#render');

        this.el.innerHTML = this.template({
            cashflow: this.collection.toJSON(),
        });

        return this;
    },

    handleDownloadClick: function (e) {
        console.debug('Results#handleDownloadClick');

        const receiptId = e.currentTarget.dataset.receipt;

        oauth2client.download(
            config.api.url + '/cashflow-receipts/' + receiptId + '?owner_id=' + encodeURIComponent(getOwnerId()),
            { method: 'GET' }
        );
    }

});