import { View } from 'backbone';
import Footer from './footer';
import template from '../templates/layout_login.hbs';

export default View.extend({

    template: template,

    initialize: function() {
        console.debug('Login#initialize');

        // Create subviews
        this.subviews = {
            footer: new Footer(),
        };
    },

    render: function() {
        console.debug('Login#render');

        this.el.innerHTML = this.template();

        // Render footer
        this.subviews.footer.setElement(this.$el.find('footer')).render();
    },

});