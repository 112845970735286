var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td class=\"w-100 align-middle\">\n                    <a href=\"#account/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"accountId") : depths[1]), depth0))
    + "/statement/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"period") : depth0), depth0))
    + "\">\n                        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"year") : depth0), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"month") : depth0), depth0))
    + "\n                    </a>\n                </td>\n                <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":66}}}))
    + " align-middle\">\n                    &yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"balance") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":14,"column":25},"end":{"line":14,"column":55}}}))
    + "\n                </td>\n                <td class=\"text-nowrap\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"pdf") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + "\n                    <button type=\"button\" title=\""
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias3,"account.download_xlsx",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":49},"end":{"line":28,"column":78}}}))
    + "\" class=\"btn btn-primary d-none d-sm-inline-block\"\n                        data-action=\"download\" data-download=\"xlsx\" data-statement=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"period") : depth0), depth0))
    + "\">\n                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\"\n                            class=\"bi bi-file-earmark-spreadsheet-fill\" viewBox=\"0 0 16 16\">\n                            <path fill-rule=\"evenodd\"\n                                d=\"M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9v1h2v2H3v1h2v2h1v-2h3v2h1v-2h3v-1h-3v-2h3V9H3zm3 3v-2h3v2H6z\" />\n                        </svg>\n                    </button>\n                </td>\n            </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" title=\""
    + alias1(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"account.download_pdf",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":49},"end":{"line":18,"column":77}}}))
    + "\"\n                        class=\"btn btn-primary d-none d-sm-inline-block\" data-action=\"download\" data-download=\"pdf\" data-statement=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"period") : depth0), depth0))
    + "\">\n                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\"\n                            class=\"bi bi-file-earmark-fill\" viewBox=\"0 0 16 16\">\n                            <path fill-rule=\"evenodd\"\n                                d=\"M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0H4zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z\" />\n                        </svg>\n                    </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n                <td>"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"account.no_statements",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":40,"column":49}}}))
    + "</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card mb-3\">\n    <div class=\"card-body\">\n        <h2 class=\"card-title h4\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.statements",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":60}}}))
    + "</h2>\n\n        <table class=\"table mb-0\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"statements") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":42,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n    </div>\n</div>";
},"useData":true,"useDepths":true});