import { history, View } from 'backbone';
import oauth2client from '../../oauth2-client';
import { removeOwnerId } from '../../owner/ownerId';
import template from '../templates/logout_modal.hbs';

export default View.extend({

    className: 'modal fade',

    events: {
        'click [data-action="logout"]': 'logout'
    },

    template: template,

    render: function () {
        console.debug('LogoutModal#render');

        this.el.innerHTML = this.template();

        this.$el.modal({
            backdrop: true,
            keyboard: true,
            show: true,
        });

        return this;
    },

    logout: function () {
        console.debug('LogoutModal#logout');

        // Clear access token
        oauth2client.clearToken();

        console.info('Index#logout: Logout successful');

        removeOwnerId();

        this.$el.modal('hide');

        // Redirect to top page
        history.navigate('/login', { trigger: true });
    }
});
