import { View } from 'backbone';
import Footer from './footer';
import Nav from './nav';
import template from '../templates/layout.hbs';

export default View.extend({

    template: template,

    initialize: function () {
        console.debug('Default#initialize');

        // Create subviews
        this.subviews = {
            nav: new Nav(),
            footer: new Footer(),
        };
    },

    render: function (options = {}) {
        console.debug('Default#render');

        this.el.innerHTML = this.template();

        // Render nav
        this.subviews.nav.setElement(this.$el.find('nav')).render(options.header);

        // Render footer
        this.subviews.footer.setElement(this.$el.find('footer')).render();
    },

});