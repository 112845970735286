'use strict';

import Backbone from 'backbone';
import oAuth2Client from './app/oauth2-client';
import Router from './app/router';
// import User from './app/user';
import googleMapsLoader from './app/google-maps-loader';
import yuyan from './app/yuyan';
import '../css/app.css';

// Define required resources
const requiredResources = [
    googleMapsLoader,
    yuyan.loadTranslations(),   // Load translations
];

jQuery(() => {

    // Override AJAX in Backbone to oAuth2Client.$ajax
    Backbone.ajax = oAuth2Client.$ajax.bind(oAuth2Client);

    // If token exists, fetch user info
    if (oAuth2Client.hasToken()) {
        // requiredResources.push(User.fetchInfo());
    }

    // Create router
    new Router();

    // When all required resources are loaded
    Promise.all(requiredResources)
        .then((resources) => {
            window.google = resources[0];   // Payload of googleMapsLoader promise

            // Start Backbone
            Backbone.history.start();
        })
        .catch((err) => {
            /** @todo Show error 500 page */
            console.error(err);
        });

});
