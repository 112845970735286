var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-primary btn-block\" data-action=\"download\" data-download=\"pdf\">\n            "
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"account.download_pdf",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":40}}}))
    + "\n        </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>\n                    <a href=\"#property/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n                </td>\n                <td class=\"d-none d-md-table-cell w-50\">\n                    "
    + alias2(__default(require("../../../handlebars-helpers/propertyAddress.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"propertyAddress","hash":{},"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":43,"column":44}}}))
    + "\n                </td>\n            </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n                <td>"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"account.no_properties",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":48,"column":49}}}))
    + "</td>\n            </tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"transaction_date") : depth0), depth0))
    + "</td>\n                <td><a href=\"#property/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"property") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a></td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"room_number") : depth0), depth0))
    + "</td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</td>\n                <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":75,"column":38},"end":{"line":75,"column":65}}}))
    + "\">&yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":75,"column":72},"end":{"line":75,"column":101}}}))
    + "</td>\n                <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"freee_receipt_ids") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":20},"end":{"line":79,"column":29}}})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <button class=\"btn btn-link py-0\" data-action=\"download\" data-receipt=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            <tr>\n                <td colspan=\"6\">"
    + container.escapeExpression(__default(require("../../../handlebars-helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"account.no_transactions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":32},"end":{"line":84,"column":63}}}))
    + "</td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.statement",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":29}}}))
    + "</h1>\n\n<hr>\n\n<div class=\"row\">\n    <div class=\"col-md-6 mb-3\">\n        <dl class=\"row mb-0\">\n            <dt class=\"col-sm-5 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.owner_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":73}}}))
    + "</dt>\n            <dd class=\"col-sm-7 mb-0\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"owner") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</dd>\n            <dt class=\"col-sm-5 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":69}}}))
    + "</dt>\n            <dd class=\"col-sm-7 mb-0\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"period_start") : stack1), depth0))
    + " - "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"period_end") : stack1), depth0))
    + "</dd>\n            <dt class=\"col-sm-5 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.bank",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":67}}}))
    + "</dt>\n            <dd class=\"col-sm-7 mb-0\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.smbc_tokyodaiichi",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":71}}}))
    + "</dd>\n            <dt class=\"col-sm-5 text-sm-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.account_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":77}}}))
    + "</dt>\n            <dd class=\"col-sm-7 mb-0\">931 "
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"deposit_account_number") : stack1), depth0))
    + "</dd>\n        </dl>\n    </div>\n\n    <div class=\"col-md-3 offset-md-2 mb-3 d-print-none\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"pdf") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\n        <button type=\"button\" class=\"btn btn-primary btn-block\" data-action=\"download\" data-download=\"xlsx\">\n            "
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.download_xlsx",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":41}}}))
    + "\n        </button>\n    </div>\n</div>\n\n<div class=\"card mb-3\">\n    <div class=\"card-body\">\n        <h2 class=\"card-title h4\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.properties",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":34},"end":{"line":34,"column":60}}}))
    + "</h2>\n\n        <table class=\"table mb-0\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"properties") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":50,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n    </div>\n</div>\n\n<div class=\"table-responsive\">\n    <table class=\"table\">\n        <thead>\n            <tr>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":59,"column":40}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.property",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":20},"end":{"line":60,"column":44}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.room_number",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":20},"end":{"line":61,"column":47}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":62,"column":47}}}))
    + "</th>\n                <th class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.amount",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":39},"end":{"line":63,"column":61}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"cashflow.receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":64,"column":45}}}))
    + "</th>\n            </tr>\n        </thead>\n\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"cashflow") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":86,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n\n        <tfoot>\n            <tr>\n                <td colspan=\"4\" class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.credit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":51},"end":{"line":91,"column":73}}}))
    + "</td>\n                <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"credit") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":92,"column":38},"end":{"line":92,"column":70}}}))
    + "\">&yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"credit") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":92,"column":77},"end":{"line":92,"column":111}}}))
    + "</td>\n                <td></td>\n            </tr>\n            <tr>\n                <td colspan=\"4\" class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.debit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":51},"end":{"line":96,"column":72}}}))
    + "</td>\n                <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"debit") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":97,"column":38},"end":{"line":97,"column":69}}}))
    + "\">&yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"debit") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":97,"column":76},"end":{"line":97,"column":109}}}))
    + "</td>\n                <td></td>\n            </tr>\n            <tr>\n                <td colspan=\"4\" class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.net",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":101,"column":51},"end":{"line":101,"column":70}}}))
    + "</td>\n                <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"net") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":102,"column":38},"end":{"line":102,"column":67}}}))
    + "\">&yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"net") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":102,"column":74},"end":{"line":102,"column":105}}}))
    + "</td>\n                <td></td>\n            </tr>\n            <tr>\n                <td colspan=\"4\" class=\"text-right\">"
    + alias2(__default(require("../../../handlebars-helpers/t.js")).call(alias1,"account.balance_end",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":51},"end":{"line":106,"column":78}}}))
    + "</td>\n                <td class=\"text-right "
    + alias2(__default(require("../../../handlebars-helpers/numberClass.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"balance") : stack1),{"name":"numberClass","hash":{},"data":data,"loc":{"start":{"line":107,"column":38},"end":{"line":107,"column":71}}}))
    + "\">&yen;"
    + alias2(__default(require("../../../handlebars-helpers/numeral.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"statement") : depth0)) != null ? lookupProperty(stack1,"balance") : stack1),"0,0",{"name":"numeral","hash":{},"data":data,"loc":{"start":{"line":107,"column":78},"end":{"line":107,"column":113}}}))
    + "</td>\n                <td></td>\n            </tr>\n        </tfoot>\n    </table>\n</div>";
},"useData":true});